<template>
    <div class="sol-banner"></div>
</template>

<script>
export default {
    props: ["title", "subtitle"],
};
</script>

<style lang="less" scoped>

.sol-banner {
    color: #fff;
    height: 450px;
    // max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    aspect-ratio: auto 1920 / 300;
    background: url("http://www.ngrsz.com/assets/Banner/666.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;

    h1 {
        font-size: 3rem;
        font-weight: 400;
        margin: 0;
    }

    p {
        margin: 5px 0 0 0;
    }
}
</style>